import React from 'react'
import Index from '../../components'
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

const IndexEn = (props) => (
  <Index
    {...props}
    events={props.data.thirdPartyEvents.collection}
    currentLanguage='en'
  />
)

IndexEn.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexEn;

export const IndexEnQuery = graphql`
  query IndexEnQuery {
    thirdPartyEvents {
      collection {
        eventId
        place {
          en
          fr
        }
        dates {
          from
          to
        }
        description {
          en
          fr
        }
      }
    }
  }
`
